import React from 'react'
import { createRoot } from 'react-dom/client'
import { v4 as uuid } from 'uuid'
import Icon from '@mdi/react'
import dynamic from 'next/dynamic'
import { mdiLoading } from '@mdi/js'
import { getStore } from 'store'
import Modal from 'components/common/Modal'
import PasswordBox from 'components/common/PasswordBox'

const Lightbox = dynamic(() => import('components/common/Lightbox/index.jsx').then((mod) => mod.default), {
  ssr: false,
})

export { toast } from 'react-toastify'

export const loading = (options) => {
  const hostElement = document.createElement('div')
  hostElement.className = 'react-loading-modal'
  document.body.appendChild(hostElement)

  const renderedContent = (
    <>
      <div className="relative mx-auto h-12 w-12">
        <div className="m-1 h-10 w-10 rounded-full border-4 border-white/10" />
        <Icon path={mdiLoading} width={48} height={48} className="absolute -top-1 left-0 z-2 text-lime-400" spin={0.8} />
      </div>
      <div className="mt-3 text-center text-tiny text-white/70">{typeof options === 'string' ? options : options.message}</div>
    </>
  )

  const handleClose = () => {
    hostElement.parentNode && hostElement.parentNode.removeChild(hostElement)
  }

  const loadingRef = {
    current: null,
  }

  createRoot(hostElement).render(
    <Modal
      open
      transparent
      width={200}
      shadow={false}
      {...options}
      maskClassName="bg-black/10"
      contentClassName="absolute items-center justify-center p-8 bg-black/70 rounded-lg backdrop-blur shadow-none"
      getRef={(ref) => (loadingRef.current = ref)}
      onClose={handleClose}
      showHeader={false}
      showFooter={false}
      closeOnBlur={false}
      showClose={false}
      usePortal={false}
      height="auto"
    >
      {renderedContent}
    </Modal>
  )

  return loadingRef
}

export const modal = (options) => {
  const { dispatch } = getStore()

  return new Promise((resolve) => {
    const modalId = uuid()

    const handleClose = () => {
      dispatch('common:destroy-modal', modalId)
      options.onClose && options.onClose()
    }

    const modal = () => (
      <Modal open usePortal height="auto" {...options} onConfirm={resolve} onCancel={resolve} onClose={handleClose}>
        {options.content && options.content(handleClose)}
      </Modal>
    )

    dispatch('common:create-modal', { id: modalId, Component: modal })
  })
}

export const requestPassword = ({ title, ...options }) => {
  const { dispatch } = getStore()
  const modalRef = { current: null }

  return new Promise((resolve) => {
    const modalId = uuid()

    const handleConfirm = (password) => {
      resolve(password)
      dispatch('common:destroy-modal', modalId)
      modalRef.current.close()
    }

    const modal = () => (
      <Modal
        open
        usePortal
        width={380}
        shadow={false}
        showMask={false}
        title={title}
        getRef={(ref) => (modalRef.current = ref)}
        contentClassName="items-center p-12"
        showConfirm={false}
        showCancel={false}
        closeOnBlur={false}
        showClose={false}
        height="auto"
      >
        <PasswordBox {...options} onConfirm={handleConfirm} />
      </Modal>
    )

    dispatch('common:create-modal', { id: modalId, Component: modal })
  })
}

export const lightbox = (options) => {
  const modalId = uuid()
  const { dispatch } = getStore()
  const { images, current, onClose, enableZoom } = options

  const handleClose = () => {
    dispatch('common:destroy-modal', modalId)
    onClose && onClose()
  }

  const modal = () => <Lightbox images={images} current={current} enableZoom={enableZoom} onRequestClose={handleClose} />

  dispatch('common:create-modal', { id: modalId, Component: modal })
}

export const confirm = (options) => {
  const { dispatch } = getStore()

  const renderedContent = typeof options.content === 'string' ? <div className="mt-3 mb-6 text-center text-tiny text-gray-500">{options.content}</div> : options.content

  return new Promise((resolve) => {
    const modalId = uuid()

    const handleClose = () => {
      dispatch('common:destroy-modal', modalId)
      options.onClose && options.onClose()
    }

    const modal = () => (
      <Modal
        open
        usePortal
        width={360}
        contentClassName="items-center px-10 py-8"
        onClose={handleClose}
        onConfirm={resolve}
        onCancel={resolve}
        closeOnBlur={false}
        showClose={false}
        height="auto"
        {...options}
      >
        {renderedContent}
      </Modal>
    )

    dispatch('common:create-modal', { id: modalId, Component: modal })
  })
}
