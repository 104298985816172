export const formatSize = (size) => {
  return size > 1024 * 1024 * 1024 ? (size / 1024 / 1024 / 1024).toFixed(2) + 'GB' : size > 1024 * 1024 ? (size / 1024 / 1024).toFixed(2) + 'MB' : (size / 1024).toFixed(2) + 'KB'
}

export const delay = (minDuration = 1000, maxDuration) =>
  new Promise((resolve) => {
    setTimeout(resolve, !maxDuration || minDuration >= maxDuration ? minDuration : minDuration + Math.ceil(Math.random() * maxDuration - minDuration))
  })

export const blobToBase64 = (blob) => {
  const reader = new FileReader()
  reader.readAsDataURL(blob)
  return new Promise((resolve) => {
    reader.onloadend = () => {
      resolve(reader.result.replace('data:font/ttf;base64,', '').replace('data:application/x-font-ttf;base64,', '').replace('data:application/octet-stream;base64,', ''))
    }
  })
}

export const getFileNameExt = (fileName, fallback = '-') => {
  return fileName && fileName.includes('.') ? fileName.split('.').slice(-1)[0] : fallback
}

export const sanitizeEmail = (email) => {
  if (!email) return ''
  const [username, domain] = email.split('@')
  return `${username.slice(0, 3)}**@${domain}`
}

export const sanitizePhone = (phone) => {
  return phone ? `${phone.slice(0, 3)}****${phone.slice(-4)}` : ''
}

export const findItemInArray = (array, key, keyName = 'id') => {
  let result
  let resultIndex = -1

  array.find((item, index) => {
    if (item[keyName] === key) {
      result = item
      resultIndex = index
      return true
    }
  })

  return [result, resultIndex]
}

export const patchItemInArray = (array, key, patch, keyName = 'id') => {
  return array.map((item) => {
    return item[keyName] === key ? Object.assign({}, item, patch) : item
  })
}

export const replaceItemInArray = (array, key, newItem, keyName = 'id') => {
  return array.map((item) => {
    return item[keyName] === key ? newItem : item
  })
}

export const removeItemFromArray = (array, key, keyName = 'id') => {
  return array.filter((item) => item[keyName] !== key)
}

export const moveUpItemInArray = (array, key, keyName = 'id') => {
  const nextArray = array.slice()
  const [item, index] = findItemInArray(array, key, keyName)
  if (index <= 0) return nextArray

  nextArray[index] = nextArray[index - 1]
  nextArray[index - 1] = item

  return nextArray
}

export const moveDownItemInArray = (array, key, keyName = 'id') => {
  const nextArray = array.slice()
  const [item, index] = findItemInArray(array, key, keyName)
  if (index === -1 || index >= array.length - 1) return nextArray

  nextArray[index] = nextArray[index + 1]
  nextArray[index + 1] = item

  return nextArray
}

export const getIndexedArray = (length, begin = 0, desc = false) => {
  const array = Array.from({ length }).map((_, index) => index + begin)
  return desc ? array.reverse() : array
}
