import React, { useEffect, useCallback, createContext, useContext, useReducer } from 'react'
import { composeState, composeStores, defaultDispatch } from './utils'
import common from './states/common'
import appearance from './states/appearance'
import user from './states/user'

const [reducers, initialState] = composeStores(common, appearance, user)

let globalState = initialState
let globalDispatch = () => undefined

export const getState = () => globalState

const reducer = (state, action) => {
  const { type: mixedType, payload } = action
  const [namespace, type] = mixedType.split(':')

  if (reducers[namespace]) {
    return composeState(namespace, state, reducers[namespace](state[namespace], { type, payload }))
  } else if (mixedType === 'reset-all') {
    return initialState
  } else {
    console.warn(`Unknown action namespace: ${namespace}`)
  }

  return state
}

export const Context = createContext({ state: initialState, dispatch: defaultDispatch })

const Modals = React.memo(({ modals }) => {
  return (
    <>
      {modals.map(({ id, Component }) => (
        <Component key={id} />
      ))}
    </>
  )
})

export const StoreProvider = React.memo((props) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const enhancedDispatch = useCallback((type, payload) => {
    if (typeof type === 'function') {
      return type(enhancedDispatch, getState)
    }

    if (typeof payload === 'function') {
      payload = payload(globalState, dispatch)
    }
    return dispatch(typeof type === 'string' ? { type, payload } : type)
  }, [])

  globalDispatch = enhancedDispatch

  useEffect(() => {
    globalState = state
  }, [state])

  return (
    <Context.Provider value={{ state, getState, dispatch: enhancedDispatch }}>
      {props.children}
      <Modals modals={state.common.modals} />
    </Context.Provider>
  )
})

export const getStore = () => {
  return {
    state: globalState,
    dispatch: globalDispatch,
  }
}

export const useStore = () => useContext(Context)

typeof globalThis !== 'undefined' && (globalThis.__getStore = getStore)
