import { useState, useEffect, useCallback } from 'react'
import { requestProfile, requestLogin, requestRegister } from 'api/user'
import { formatMessage } from 'utils/format'
import { toast } from 'helpers/UI'
import { updateProfile } from 'actions/user'
import { useStore } from 'store'
import { useRouter } from 'next/router'
import { captchaValidate, clearConnectingFlag } from 'helpers/Auth'
import { isEmail } from 'utils/validate'

export const useProfile = (forceUpdate) => {
  const { state, dispatch } = useStore()

  useEffect(() => {
    dispatch(updateProfile(forceUpdate))
  }, [forceUpdate])

  return state.user
}

export const useLogin = () => {
  const router = useRouter()
  const { dispatch } = useStore()
  const [loading, setLoading] = useState(false)

  const submitLogin = useCallback(async (params) => {
    const { identifier, password } = params
    if (!identifier || !password) {
      toast.error('请填写邮箱和密码')
      return false
    }

    setLoading(true)

    const connectToken = sessionStorage.getItem('jianli_connecting_token')
    connectToken && (params.connectToken = connectToken)

    try {
      await requestLogin(params)
      const user = await requestProfile()

      setLoading(false)
      dispatch('user:update-profile', user)
      clearConnectingFlag()

      return { succeed: true, user }
    } catch (error) {
      setLoading(false)

      if (error.message === '你的邮箱地址还未验证') {
        sessionStorage.setItem('unconfirmed_email', identifier)
        return router.push('/auth/confirmation')
      }

      toast.error(error.message)
      return { succeed: false, error }
    }
  })

  return [submitLogin, loading]
}

export const useRegister = () => {
  const [loading, setLoading] = useState(false)

  const submitRegister = useCallback(async (params) => {
    const { username, email, password, invitation } = params

    if (username.trim().length < 3) {
      toast.error('请填写有效的用户名')
      return false
    }

    if (!isEmail(email)) {
      toast.error('请填写有效的邮箱')
      return false
    }

    if (password.trim().length < 3) {
      toast.error('请填写有效的密码')
      return false
    }

    setLoading(true)

    try {
      const { randstr, ticket } = await captchaValidate()
      const registerParams = { username, email, password, invitation, validation: { randstr, ticket } }
      const connectToken = sessionStorage.getItem('jianli_connecting_token')

      connectToken && (registerParams.connectToken = connectToken)

      const response = await requestRegister(registerParams)
      const { message } = response.register || {}

      setLoading(false)
      clearConnectingFlag()

      if (response.user?.id) {
        return { succeed: true, user: response.user }
      } else {
        toast.error(message)
        return { succeed: false, message }
      }
    } catch (error) {
      setLoading(false)
      error && toast.error(formatMessage(error.message))
      return { succeed: false, error }
    }
  })

  return [submitRegister, loading]
}
