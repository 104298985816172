import React from 'react'
import ReactDOM from 'react-dom'
import Icon from '@mdi/react'
import Router from 'next/router'
import { mdiClose } from '@mdi/js'
import cx from 'classnames'
import './styles.scss'

export default class Modal extends React.PureComponent {
  static defaultProps = {
    open: false,
    shadow: true,
    showMask: true,
    showHeader: true,
    showFooter: true,
    closeOnBlur: true,
    showClose: true,
    showCancel: true,
    showConfirm: true,
    confirmable: true,
    closeOnCancel: true,
    closeOnConfirm: true,
    type: 'primary',
    className: '',
    maskClassName: '',
    contentClassName: '',
    width: 480,
    height: 360,
    usePortal: true,
    keepAlive: false,
  }

  transitionEnded = false

  state = {
    hidden: true,
    active: false,
  }

  constructor(props) {
    super(props)

    props.getRef && props.getRef(this)

    if (this.props.usePortal) {
      this.hostElement = document.createElement('div')
      this.hostElement.className = 'react-modal-root'
      document.body.appendChild(this.hostElement)
    }
  }

  componentDidMount() {
    this.props.open && this.showModal()
    Router.events.on('routeChangeStart', this.hideModal)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      this.props.open ? this.showModal() : this.hideModal()
    }
  }

  componentWillUnmount() {
    this.props.usePortal && document.body.removeChild(this.hostElement)
    Router.events.off('routeChangeStart', this.hideModal)
  }

  handleTransitionEnd = (event) => {
    if (event.target === event.currentTarget && !this.state.active && !this.transitionEnded) {
      this.transitionEnded = true
      this.setState({ hidden: true })
      this.props.onClose && this.props.onClose()
    }
  }

  showModal = () => {
    this.setState({ hidden: false }, () => {
      setTimeout(() => this.setState({ active: true }), 20)
    })
  }

  hideModal = () => {
    this.setState({ active: false }, () => {
      setTimeout(() => {
        if (!this.transitionEnded) {
          this.setState({ hidden: true })
          this.props.onClose && this.props.onClose()
        }
      }, 500)
    })
  }

  show = () => {
    this.showModal()
  }

  open = () => {
    this.showModal()
  }

  hide = () => {
    this.hideModal()
  }

  close = () => {
    this.hideModal()
  }

  render() {
    let {
      type,
      title,
      className,
      maskClassName,
      contentClassName,
      transparent,
      shadow,
      width,
      height,
      children,
      confirmable,
      showMask,
      showHeader,
      showFooter,
      showCancel,
      showConfirm,
      showClose,
      cancelText,
      confirmText,
      bottomText,
      usePortal,
    } = this.props

    const { active, hidden } = this.state
    const renderedChildren = React.Children.map(children, (child) => {
      return typeof child.type === 'string' ? child : React.cloneElement(child, { $modal: this })
    })

    if (hidden) return null

    const renderedContentClassName = cx('react-modal-content', contentClassName, {
      'bg-white': !transparent,
      'shadow-xl shadow-black/10': shadow,
    })

    const modalWrapper = (
      <div className={cx('react-modal', className, { active })}>
        {showMask && <div className={`react-modal-mask ${maskClassName}`} onClick={this.handleMaskClick}></div>}
        <div className="react-modal-content-wrapper">
          <div style={{ width, height }} className={renderedContentClassName} onTransitionEnd={this.handleTransitionEnd}>
            {showHeader && (
              <div className="react-modal-header">
                <h3 className="react-modal-caption">{title}</h3>
                {showClose && (
                  <button type="button" onClick={this.handleCancel} className="react-modal-close-button">
                    <Icon color="#626364" width={16} height={16} path={mdiClose} />
                  </button>
                )}
              </div>
            )}
            <div className="react-modal-body">{renderedChildren}</div>
            {showFooter ? (
              <div className="react-modal-footer">
                <div className="react-modal-addon-text">{bottomText}</div>
                <div className="react-modal-buttons">
                  {showCancel && (
                    <button type="button" onClick={this.handleCancel} className="button-normal react-modal-cancel h-10 rounded text-sm font-medium">
                      {cancelText || '取消'}
                    </button>
                  )}
                  {showConfirm && (
                    <button type="button " onClick={this.handleConfirm} className={`button-${type} react-modal-confirm h-10 rounded text-sm font-medium ${!confirmable ? 'disabled' : ''}`}>
                      {confirmText || '确定'}
                    </button>
                  )}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    )

    return usePortal ? ReactDOM.createPortal(modalWrapper, this.hostElement) : modalWrapper
  }

  handleCancel = () => {
    this.props.closeOnCancel && this.handleClose('cancel')
    this.props.onCancel && this.props.onCancel(false)
  }

  handleConfirm = () => {
    this.props.closeOnConfirm && this.handleClose('confirm')
    this.props.onConfirm && this.props.onConfirm(true)
  }

  handleMaskClick = () => {
    this.props.closeOnBlur && this.handleClose('mask')
    this.props.onBlur && this.props.onBlur()
  }

  handleClose = () => {
    this.hideModal()
    this.props.onBeforeClose && this.props.onBeforeClose('close')
  }
}
