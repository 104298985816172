import { requestProfile, requestLogout } from 'api/user'
// import events from 'helpers/Events'

export const updateProfile = (forceUpdate) => {
  return async (dispatch, getState) => {
    if (!forceUpdate) return getState().user

    try {
      const profile = await requestProfile()
      if (profile) {
        dispatch('user:update-profile', profile)
        return profile
      }
      dispatch('user:update-profile', {})
      return null
    } catch (error) {
      dispatch('user:update-profile', {})
      return null
    }
  }
}

export const logout = () => {
  return async (dispatch) => {
    await requestLogout()
    dispatch('user:logout')
    // TODO 通过全局消息通知其他组件清理转改，而不是直接刷新页面
    // events.emit('user-logout')
    location.reload()
  }
}
