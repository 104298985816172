import React, { useMemo } from 'react'
import Link from 'next/link'
import cx from 'classnames'
import { withRouter } from 'next/router'

const NavLink = React.memo((props) => {
  const { router, children, exact, subPaths, href, className, normalClassName = '', activeClassName = 'active', match = 'pathname', ...restProps } = props

  const isActive = useMemo(() => {
    return (exact ? router[match] === props.href : router[match].indexOf(props.href) === 0) || subPaths.some((path) => router[match].indexOf(path) === 0)
  }, [className, exact, subPaths, router[match]])

  return (
    <Link href={href}>
      <a {...restProps} href={href} className={cx(className, { [activeClassName]: isActive, [normalClassName]: !isActive })}>
        {children}
      </a>
    </Link>
  )
})

NavLink.defaultProps = {
  exact: false,
  subPaths: [],
}

export default withRouter(NavLink)
