import React, { useState } from 'react'
import Icon from '@mdi/react'
import { mdiAccount, mdiLink, mdiImageFilterHdr, mdiCodeTags } from '@mdi/js'
import cx from 'classnames'
import { useEffect } from 'react'

const fallbackIcons = {
  normal: mdiImageFilterHdr,
  avatar: mdiAccount,
  link: mdiLink,
  template: mdiCodeTags,
}

const ImageWithFadeEffect = (props) => {
  const { src, alt, className, ...restProps } = props
  const [imgSrc, setImgSrc] = useState(null)
  const [loaded, setLoaded] = useState(false)

  const handleLoad = () => {
    setLoaded(true)
  }

  useEffect(() => setImgSrc(src), [src])

  return <img src={imgSrc} className={cx('image transition duration-500', loaded ? '80 scale-100 opacity-100' : 'scale-90 opacity-0', className)} onLoad={handleLoad} alt={alt} {...restProps} />
}

const ReactImage = React.memo((props) => {
  let { src, alt, crop, thumb, type, fade, className, fallbackIconClassName, ...restProps } = props

  if (!src) {
    return (
      <div className={cx('image flex items-center justify-center bg-gray-100 p-2', className)}>
        <Icon path={fallbackIcons[type]} className={fallbackIconClassName} />
      </div>
    )
  }

  if (crop) {
    src = `${src}?imageMogr2/crop/${crop}`
  } else if (thumb) {
    src = `${src}?imageMogr2/thumbnail/${thumb}>`
  }

  if (!fade) return <img src={src} alt={alt} className={cx('image', className)} {...restProps} />

  return <ImageWithFadeEffect src={src} className={className} {...restProps} />
})

ReactImage.defaultProps = {
  type: 'normal',
  fade: false,
  fallbackIconClassName: 'w-12 h-12 text-gray-400',
}

export const loadImage = (src, width, height, crossOrigin = '') =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.crossOrigin = crossOrigin
    image.width = width
    image.height = height
    image.onload = () => resolve(image)
    image.onerror = reject
    image.onabort = reject
    image.src = src
  })

export const BaseImage = ({ alt, src = '', ...props }) => {
  if (src.startsWith('/')) {
    src = `${process.env.NEXT_PUBLIC_ASSETS_PATH || ''}${src}`
  }

  return <img src={src} alt={alt} {...props} />
}

export default ReactImage
