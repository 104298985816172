const handleDOMTracking = (event) => {
  if (event.target && event.target.dataset.eventName) {
    const eventProps = {}
    const { eventName, ...restProps } = event.target.dataset

    Object.keys(restProps).forEach((key) => {
      if (key.startsWith('event')) {
        eventProps[key.replace(/event/, '').toLowerCase()] = restProps[ley]
      }
    })

    track(eventName, eventProps)
  }
}

let analyzer

export const initAnalyzer = () =>
  new Promise((resolve, reject) => {
    if (typeof window === 'undefined') return reject(null)

    if (analyzer) return resolve(analyzer)

    let script = document.getElementById('analytics-script')

    if (!script) {
      script = document.createElement('script')
      script.id = 'analytics-script'
      script.defer = true
      script.dataset.domain = process.env.NEXT_PUBLIC_ANALYTICS_SITE_ID || 'jianli.online'
      script.src = 'https://stat.jianli.online/js/plausible.js'
      script.addEventListener('load', () => {
        window.plausible =
          window.plausible ||
          function () {
            ;(window.plausible.q = window.plausible.q || []).push(arguments)
          }
        analyzer = window.plausible
        resolve(analyzer)
      })

      document.body.addEventListener('click', handleDOMTracking)

      document.body.appendChild(script)
    } else {
      script.addEventListener('load', () => {
        setTimeout(() => resolve(analyzer), 0)
      })
    }
  })

export const track = (name, props) => {
  initAnalyzer().then((analyzer) => {
    if (process.env.NODE_ENV === 'development') {
      console.log(`[analyzer]Track event "${name}" with data:`, props)
    }
    analyzer(name, { props })
  })
}
