import React, { useEffect, useState, useCallback, useContext, createContext, useImperativeHandle, useRef } from 'react'
import cx from 'classnames'
import dynamic from 'next/dynamic'
import Input, { Textarea } from 'components/common/Input'
import Checkbox, { CheckboxGroup } from 'components/common/Checkbox'
import Select from 'components/common/Select'
import Switch from 'components/common/Switch'
import ColorPicker from 'components/common/ColorPicker'
// import CitySelector from 'components/common/CitySelector'
import { DateSelect } from 'components/common/Date'
import { RangeSelect } from 'components/common/Range'

const componentMap = {
  text: Input,
  number: Input,
  password: Input,
  textarea: Textarea,
  image: dynamic(() => import('components/common/ImagePicker/index.jsx').then((mod) => mod.default), {
    ssr: false,
  }),
  switch: Switch,
  checkbox: Checkbox,
  checkboxGroup: CheckboxGroup,
  select: Select,
  date: DateSelect,
  range: RangeSelect,
  color: ColorPicker,
  // city: CitySelector,
}

const hasValue = (value, type) => {
  if (componentMap[type]?.emptyChecker) {
    return componentMap[type]?.emptyChecker(value)
  }

  return typeof value === 'boolean' ? true : !!value
}

const errorMap = {
  ERROR_FIELD_REQUIRED: 1,
  ERROR_FIELD_NONE_MATCH: 2,
  ERROR_CUSTOMER: 3,
}

export const FormContext = createContext({
  values: {},
  errors: {},
  unsaved: false,
  onChange: () => null,
})

export const useForm = () => {
  const { values, errors, unsaved, onSubmit, onChange } = useContext(FormContext)

  return {
    values,
    errors,
    unsaved,
    setValues: onChange,
    onSubmit: onSubmit,
  }
}

export const useFormField = (name, { validator, pattern, required } = {}) => {
  if (!name) return null

  const { values, errors, unsaved, onSubmit, onChange, registerValidator, unregisterValidator } = useContext(FormContext)

  const handleChange = useCallback(
    (value, triggerUnsaved = false) => {
      onChange({ [name]: value }, triggerUnsaved)
    },
    [name, onChange]
  )

  useEffect(() => {
    registerValidator(name, { validator, pattern, required })

    return () => {
      unregisterValidator(name)
    }
  }, [name, validator, pattern, required])

  return {
    value: values[name],
    error: errors[name],
    values,
    errors,
    unsaved,
    setValue: handleChange,
    setValues: onChange,
    submit: onSubmit,
  }
}

export const FormField = React.memo((props) => {
  const { simple, trim, type, label, name, value, required, pattern, validator, onChange, component, triggerUnsaved = true, actions, children, className, innerClassName, ...restProps } = props

  const { values, errors, onChange: contextOnChange, registerValidator, unregisterValidator } = useContext(FormContext)
  const Component = children ? null : component || componentMap[type]
  const renderedValue = typeof value === 'undefined' ? values[name] : value

  const handleChange = useCallback(
    (value) => {
      trim && (value = value.trim())
      onChange ? onChange(value, name) : contextOnChange({ [name]: value }, triggerUnsaved)
    },
    [name, triggerUnsaved, onChange, trim, contextOnChange]
  )

  useEffect(() => {
    registerValidator && registerValidator(name, { type, validator, pattern, required })

    return () => {
      unregisterValidator && unregisterValidator(name)
    }
  }, [name, type, validator, pattern, required])

  if (simple) {
    return Component ? <Component {...restProps} type={type} error={errors[name]} className={className} value={renderedValue} onChange={handleChange} /> : children
  }

  const appliedClassName = cx(`flex flex-col items-stretch relative field-${name}`, className, { error: errors[name] })

  return (
    <div className={appliedClassName}>
      {(!!label || !!actions) && (
        <div className="flex items-center justify-between">
          <span className="mb-2 text-sm text-gray-500">{label}</span>
          {actions}
        </div>
      )}
      {Component ? <Component {...restProps} type={type} error={errors[name]} value={renderedValue || ''} className={innerClassName} onChange={handleChange} /> : children}
      <div className="absolute bottom-0 left-0 translate-y-full text-mini text-red-600">{errors[name] && errors[name].message}&ensp;</div>
    </div>
  )
})

FormField.defaultProps = {
  type: 'text',
}

const handleRawSubmit = (event) => event.preventDefault()

const validateSingleField = (value, values, validator) => {
  if (!validator) return null

  if (validator.required && !hasValue(value, validator.type)) {
    return {
      type: errorMap.ERROR_FIELD_REQUIRED,
      message: '该项必填',
    }
  }

  if (validator.pattern && !validator.pattern.test(value)) {
    return {
      type: errorMap.ERROR_FIELD_NONE_MATCH,
      message: '该项不符合要求',
    }
  }

  if (validator.validator) {
    const result = validator.validator(value, values)
    if (result !== true) {
      return {
        type: errorMap.ERROR_CUSTOMER,
        error: result,
      }
    }
  }

  return null
}

const Form = React.memo(
  React.forwardRef((props, ref) => {
    const { className, children, initialValues, onChange, onSubmit } = props
    const validators = useRef({})
    const valuesRef = useRef({})
    const [errors, setErrors] = useState({})
    const [unsaved, setUnsaved] = useState(false)
    const [values, setValues] = useState(initialValues)

    const registerValidator = useCallback(
      (name, validator) => {
        validators.current[name] = validator
        valuesRef.current[name] = initialValues[name] || null
      },
      [initialValues]
    )

    const unregisterValidator = useCallback((name) => {
      delete validators.current[name]
    }, [])

    const validateFields = useCallback((values = null) => {
      const errors = {}
      const fieldsToValidate = values || valuesRef.current

      Object.keys(fieldsToValidate).forEach((name) => {
        errors[name] = validateSingleField(fieldsToValidate[name], valuesRef.current, validators.current[name])
      })

      setErrors((prevErrors) => Object.assign({}, prevErrors, errors))
      return errors
    }, [])

    const handleChange = useCallback((nextValues, triggerUnsaved = true) => {
      if (triggerUnsaved) {
        setUnsaved(true)
      }

      validateFields(nextValues)

      setValues((prevValues) => {
        nextValues = typeof nextValues === 'function' ? nextValues(prevValues) : nextValues
        return Object.assign({}, prevValues, nextValues)
      })
    }, [])

    const handleSubmit = useCallback(
      (event) => {
        const errors = validateFields()

        // If any field not passed its validator, then prevent to submit.
        if (Object.keys(errors).some((key) => errors[key])) return false

        setUnsaved(false)
        onSubmit && onSubmit(values, event)
      },
      [values, onSubmit]
    )

    const handleFormClick = useCallback(
      (event) => {
        if (event.target.type === 'submit' || event.target.role === 'submit') {
          handleSubmit(event)
        }
      },
      [handleSubmit]
    )

    useEffect(() => {
      onChange && onChange(values)
      valuesRef.current = Object.assign({}, valuesRef.current, values)
    }, [values, onChange])

    useEffect(() => {
      setValues(initialValues)
    }, [initialValues])

    useImperativeHandle(ref, () => ({
      setValues,
      setUnsaved,
      validateFields,
      getValues: () => values,
      getUnsaved: () => unsaved,
    }))

    return (
      <FormContext.Provider
        value={{
          values,
          errors,
          unsaved,
          validateFields,
          onSubmit: handleSubmit,
          onChange: handleChange,
          registerValidator,
          unregisterValidator,
        }}
      >
        <form className={className} onClick={handleFormClick} onSubmit={handleRawSubmit}>
          {children}
        </form>
      </FormContext.Provider>
    )
  })
)

Form.FormField = FormField

Form.defaultProps = {
  initialValues: {},
}

export default Form
