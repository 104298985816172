import request from './index'

export const requestLogin = (data) => request.post('/auth/local', data)
export const requestLogout = () => request.post('/auth/logout')
export const requestRegister = (data) => request.post('/auth/local/register', data)

export const requestSendEmailCaptcha = (action, data) => request.post(`/auth/send-email-captcha/${action}`, data)
export const requestSendPhoneCaptcha = (action, data) => request.post(`/auth/send-phone-captcha/${action}`, data)
export const requestPhoneLogin = (data) => request.post('/auth/login/phone', data)

export const requestSendConfirmationEmail = (email, validation) => request.post('/auth/send-email-confirmation', { email, validation })
export const requestEmailConfirmation = (code) => request.get(`/auth/email-confirmation?confirmation=${code}&redirect=0`)

export const requestAccountConnect = (action, data) => request.post(`/auth/connect/${action}`, data)
export const requestAccountDisconnect = (action, data) => request.post(`/auth/disconnect/${action}`, data)

export const requestUserPassports = () => request.get('/passport/connected')
export const requestSignInViaProvider = ({ provider, token, connectToken }) => request.post(`/passport/authorize/${provider}`, { token, connectToken })
export const requestConnectToProvider = (provider, token, action) => request.post(`/passport/connect/${provider}`, { token, action })
export const requestDisconnectFromProvider = (provider) => request.post(`/passport/disconnect/${provider}`)

export const requestSendPasswordResetEmail = (email, validation) => request.post('/auth/forgot-password?type=email', { email, validation })
export const requestPasswordResettingEmail = (code) => request.get(`/auth/reset-password-email/${code}`)
export const requestResetPassword = (data) => request.post('/auth/reset-password', data)
export const requestResetPasswordViaCaptcha = (data) => request.post('/auth/reset-password-captcha', data)

export const requestClaimBetaGift = () => request.post('/users/claim-beta-gift')

export const requestProfile = () =>
  request.get('/users/me').then((res) => {
    const resutlt = res.data

    if (resutlt?.profile?.homepageResume?.basefile) {
      resutlt.profile.homepageResume.basefile = {
        data: resutlt.profile.homepageResume.basefile,
      }
    }

    return resutlt
  })
export const requestUpdateProfile = (data) => request.put('/users/me', { data })

export const requestInvitedUsers = () => request.get('/users/me/invites').then((res) => res.data)

export const requestUserProfile = (username) => request.get(`/profiles/${username}`).then((res) => res.data)

export const requestPreference = () => request('/preferences/me')
export const requestUpdatePreference = (data) => request('/preferences/me', data)
