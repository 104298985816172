import Head from 'next/head'

const BaseHead = (props) => {
  return (
    <Head>
      <meta charSet="UTF-8" />
      <meta name="description" content="简约简历：轻松创建优美的在线简历，一键分享在线链接，也可下载高清PDF简历；关联你的作品到简历上，更加充分地展示你的才能！"></meta>
      <meta name="keywords" content="在线简历，简历制作，简约风格简历，个人简历，免费简历，简历模板，简历下载" />
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      <meta name="renderer" content="webkit" />
      <meta name="force-rendering" content="webkit" />
      <meta name="format-detection" content="telephone=no" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="theme-color" content="#1a1b20" />
      <meta name="apple-mobile-web-app-status-bar-style" content="transparent" />
      <meta name="x5-page-mode" content="app" />
      <meta name="x5-fullscreen" content="true"></meta>
      <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
      <link rel="apple-touch-icon icon" href="/icons/favicon.png" />
      <link rel="shortcut icon" href="/icons/favicon.png" />
      <title>{props.title}</title>
    </Head>
  )
}

export default BaseHead
