import Router from 'next/router'
import dynamic from 'next/dynamic'
import { getStore } from 'store'
import { updateProfile } from 'actions/user'
import { modal } from 'helpers/UI'

const AuthorizeModal = dynamic(() => import('components/modules/authorize/Modal').then((mod) => mod.default), {
  ssr: false,
})

export const authorizeFormLink = async (event) => {
  sessionStorage.setItem('route_path_before_authorize', location.pathname)
  const targetPath = event.currentTarget.href

  if (targetPath.startsWith('/api')) {
    location.href = targetPath
  } else {
    await Router.push(targetPath)
  }

  event.preventDefault()
}

export const redirectAfterAuthorize = () => {
  const redirectPath = sessionStorage.getItem('route_path_before_authorize')
  if (redirectPath) {
    Router.replace(redirectPath)
  } else {
    Router.replace('/')
  }
}

export const checkAuth = async (forceUpdate) => {
  const { dispatch } = getStore()
  let userInfo = await dispatch(updateProfile(forceUpdate))

  if (userInfo?.authorized) {
    return true
  }

  await new Promise(async (resolve, reject) => {
    await modal({
      width: 'auto',
      showFooter: false,
      transparent: true,
      usePortal: true,
      contentClassName: 'bg-white',
      content: (closeModal) => <AuthorizeModal onAuthorize={resolve} onClose={closeModal} />,
    })
    reject({ message: '取消登录' })
  })

  userInfo = await dispatch(updateProfile())
  return userInfo?.authorized
}

export const captchaValidate = () => {
  return new Promise(async (resolve, reject) => {
    let script = document.getElementById('tencent_captcha_script')

    const handleValidate = (TencentCaptcha) => {
      try {
        const captcha = new TencentCaptcha(
          process.env.NEXT_PUBLIC_TENCENT_CAPTCHA_ID,
          (result) => {
            if (!result) {
              reject('验证出错，请重试')
            } else if (result.ret === 0) {
              resolve(result)
            } else if (result.ret === 2) {
              reject(null)
            } else {
              reject('验证出错，请重试')
            }
          },
          {}
        )

        captcha.show()
      } catch (error) {
        console.warn(error)
        reject('加载出错，请重试')
      }
    }

    window.TencentCaptcha = window.TencentCaptcha || null

    // TODO
    // 这里有一个触发概率比较低的小问题，script标签存在并不代表TencentCaptcha已经初始化，可能会导致一些异常

    if (!script) {
      script = document.createElement('script')

      script.onload = () => {
        handleValidate(window.TencentCaptcha)
      }

      script.onabort = () => reject('加载出错，请重试')
      script.onerror = () => reject('加载出错，请重试')
      script.id = 'tencent_captcha_script'
      script.src = 'https://ssl.captcha.qq.com/TCaptcha.js'

      document.body.appendChild(script)
    } else {
      handleValidate(window.TencentCaptcha)
    }
  })
}

export const clearConnectingFlag = () => {
  sessionStorage.removeItem('jianli_connecting_token')
  sessionStorage.removeItem('jianli_connecting_avatar')
  sessionStorage.removeItem('jianli_connecting_nickname')
  sessionStorage.removeItem('jianli_connecting_provider')
}
