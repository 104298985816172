import React, { useEffect, useState } from 'react'
import dynamic from 'next/dynamic'
import { StoreProvider, useStore } from 'store'
import SiteHead from 'components/modules/base/Head'
import { initialize } from 'helpers/Initialize'
import layouts from 'components/layouts'
import { useProfile } from 'hooks/user'

const ToastContainer = dynamic(() => import('components/common/ToastContainer').then((mod) => mod.default), { ssr: false })
const PageSignIn = dynamic(() => import('pages/auth/login').then((mod) => mod.default), { ssr: false })

const MainComponent = React.memo(({ Component, pageProps }) => {
  const { state } = useStore()
  const user = useProfile(true)
  const { common } = state
  const { authorized, initialized } = user

  if (Component.options?.protected) {
    if (!initialized) return null // TODO [优化] 全局的页面加载效果
    if (!authorized) {
      Component = PageSignIn
      Component.options = {
        layoutClassName: 'h-screen bg-gray-200/70',
        withNavBar: false,
      }
    }
  }

  const { layout: pageLayout, title } = Component.options || {}
  const layout = layouts[pageLayout] || layouts.default

  return (
    <>
      <SiteHead title={common.pageTitle ? `${common.pageTitle} | 简约简历` : title ? `${title} | 简约简历` : '简约简历'} />
      {layout(<Component {...pageProps} />, Component.options)}
    </>
  )
})

const AppEntry = (props) => {
  const { Component, pageProps } = props
  const [ready, setReady] = useState(!!Component.options?.ssr)

  useEffect(() => {
    setReady(true)
    initialize()
  }, [])

  if (!ready) {
    return <SiteHead title="简约简历" />
  }

  if (Component.options?.pure) {
    return <Component {...pageProps} />
  }

  return (
    <>
      <StoreProvider>
        <MainComponent Component={Component} pageProps={pageProps} />
      </StoreProvider>
      <ToastContainer />
    </>
  )
}

export default AppEntry
