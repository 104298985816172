import React from 'react'
import Link from 'next/link'

const iconClassNames = {
  normal: 'mt-1 scale-80',
  large: 'mr-1 scale-100',
}

const textClassNames = {
  normal: 'ml-2 text-base',
  large: 'ml-3 text-xl',
}

const contentClassNames = {
  light: 'text-white',
  dark: 'text-gray-600 ',
}

const Logo = (props) => {
  const { size, className, withText, contentStyle } = props

  return (
    <Link href="/">
      <a className={`relative z-2 flex items-center ${className || ''}`}>
        <div className={`relative top-[-2px] left-[-2px] h-8 w-7 ${iconClassNames[size]}`}>
          <div className="absolute top-0 left-0 z-1 flex h-8 w-7 flex-col items-center justify-center rounded bg-gradient-to-br from-primary/80 to-primary">
            <div className="flex w-4 items-center justify-between">
              <div className="h-[7px] w-[7px] bg-white" />
              <div className="ml-[3px] flex-1">
                <div className="h-[2px] bg-white" />
                <div className="mt-[3px] h-[2px] bg-white" />
              </div>
            </div>
            <div className="mt-[3px] h-[2px] w-4 bg-white" />
            <div className="mt-[3px] h-[2px] w-4 bg-white" />
          </div>
          <div className="absolute top-[3px] left-[3px] z-0 h-8 w-7 rounded bg-red-300" />
        </div>
        {withText && (
          <h1 className={`flex items-center ${textClassNames[size]} ${contentClassNames[contentStyle]}`}>
            <span>简约简历</span>
            <small className="ml-1 scale-90 rounded border border-amber-400 bg-amber-200 px-1 text-xs font-bold text-amber-600">BETA</small>
          </h1>
        )}
      </a>
    </Link>
  )
}

Logo.defaultProps = {
  size: 'normal',
  withText: false,
  contentStyle: 'dark',
}

export default Logo
