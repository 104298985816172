const initialState = {
  loading: false,
  initialized: false,
  authorized: false,
  id: null,
  nickname: null,
  phone: null,
  email: null,
  confirmed: false,
  points: 0,
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'update-profile':
      return Object.assign(
        {},
        state,
        {
          initialized: true,
          loading: false,
          authorized: state.authorized || !!action.payload.id,
        },
        action.payload,
        {
          nickname: action.payload.nickname || action.payload.username || state.nickname,
        }
      )
    case 'logout':
      return Object.assign({}, initialState, { initialized: true })
    default:
      return state
  }
}

export default { namespace: 'user', initialState, reducer }
