import React, { useState, useCallback } from 'react'
import Icon from '@mdi/react'
import { mdiLockOutline } from '@mdi/js'
import { FormField } from 'components/common/Form'

const PasswordBox = React.memo((props) => {
  const { message, onConfirm, $modal, ...restProps } = props
  const [password, setPassword] = useState('')

  const handleKeyUp = useCallback(
    (event) => {
      if (event.key === 'Enter' && !event.nativeEvent.isComposing) {
        onConfirm(password)
      }
    },
    [password, onConfirm]
  )

  const handleConfirm = useCallback(() => {
    onConfirm(password)
  }, [password, onConfirm])

  return (
    <div className="mx-2 mt-2 flex flex-col items-center">
      <span className="text-tiny text-gray-500">{message}</span>
      <FormField
        actions={<Icon size={1} className="absolute top-5 left-4 text-gray-300" path={mdiLockOutline} />}
        className="mt-5 w-64"
        innerClassName="pl-12 bg-transparent shadow-line shadow-gray-200"
        value={password}
        type="password"
        maxLength={32}
        onKeyUp={handleKeyUp}
        onChange={setPassword}
        {...restProps}
      />
      <button className="button-primary mt-3 h-12 w-64 rounded text-sm font-medium" onClick={handleConfirm}>
        确定
      </button>
    </div>
  )
})

export default PasswordBox
