import React, { useCallback } from 'react'
import cx from 'classnames'
import './styles.scss'

const Switch = React.memo((props) => {
  const { name, value, disabled, onChange, onClick, theme, size, className, ...restProps } = props

  const handleClick = useCallback(
    (event) => {
      if (disabled) return false
      if (onClick && onClick(event) === false) return false

      onChange && onChange(!value, name, event)
    },
    [name, value, disabled, onClick, onChange]
  )

  return <div className={cx('component-switch', className, `theme-${theme}`, size, { checked: value, disabled })} onClick={handleClick} {...restProps} />
})

Switch.defaultProps = {
  size: 'normal',
  theme: 'primary',
}

export default Switch
