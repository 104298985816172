export const composeStores = (...stores) => {
  return stores.reduce(
    ([reducers, initialState], store) => {
      reducers[store.namespace] = store.reducer
      initialState[store.namespace] = store.initialState
      return [reducers, initialState]
    },
    [{}, {}]
  )
}

export const composeState = (namespace, state, subState) => {
  return Object.assign({}, state, { [namespace]: subState })
}

export const defaultDispatch = () => undefined
