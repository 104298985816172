import React from 'react'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { initAnalyzer } from './Analytics'
import 'dayjs/locale/zh-cn'
import 'react-image-lightbox/style.css'
import 'animate.css/animate.min.css'
import 'assets/scss/_base.scss'

dayjs.locale('zh-cn')
dayjs.extend(relativeTime)

globalThis.React = React

export const initialize = () => {
  initAnalyzer().then(() => console.log('[DEBUG] Analyzer initialized!'))
}
