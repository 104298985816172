import React, { useCallback } from 'react'
import { getIndexedArray } from 'utils/base'
import Select from 'components/common/Select'

export const RangeSelect = React.memo((props) => {
  const { name, onChange, min, max, desc, options, divider } = props
  const value = props.value || []
  const [begin, end] = value
  const rangeOptions = options || getIndexedArray(max - min + 1, min, desc).map((item) => ({ value: item, label: `${item}年` }))

  const handleChange = useCallback(
    (singleValue, singleName) => {
      const nextValue = value.slice()
      nextValue[singleName === 'begin' ? 0 : 1] = singleValue * 1
      onChange(nextValue, name)
    },
    [name, value, onChange]
  )

  const getOptionsDisabled = useCallback((value, name) => (name === 'begin' ? value > end : value < begin), [begin, end])

  return (
    <div className="flex items-center">
      <Select name="begin" value={begin} options={rangeOptions} onChange={handleChange} disabled={getOptionsDisabled} />
      <span className="mx-2 text-gray-400">{divider}</span>
      <Select name="end" value={end} options={rangeOptions} onChange={handleChange} disabled={getOptionsDisabled} />
    </div>
  )
})

RangeSelect.defaultProps = {
  divider: '-',
  desc: true,
  value: [],
}

export const RangeSlider = null
