import React from 'react'

const year = new Date().getFullYear()

export const BrandFooter = React.memo((props) => {
  return (
    <footer className="mx-auto flex flex-shrink-0 flex-col items-center justify-between py-5 lg:h-16 lg:flex-row lg:py-0 xl:w-1200" data-static={props.static}>
      <a className="text-xs text-gray-500 hover:underline" href="https://jianli.online">
        &copy; {year}&ensp;简约简历
      </a>
      <div className="mt-1 space-x-2 lg:mt-0">
        <a className="text-xs text-gray-500 hover:underline" href="https://beian.miit.gov.cn/" target="_blank">
          湘ICP备2022024252号
        </a>
        <span className="text-xs text-gray-300">/</span>
        <a className="text-xs text-gray-500 hover:underline" href="https://www.beian.gov.cn/" target="_blank">
          湘公网安备 43011202000874号
        </a>
      </div>
    </footer>
  )
})

export default BrandFooter
