import React, { useCallback, useMemo } from 'react'

const ColorPicker = React.memo((props) => {
  const { name, value, onChange } = props

  const handleChange = useCallback(
    (event) => {
      onChange && onChange(event.target.value, name, event)
    },
    [name, onChange]
  )

  const renderedStyle = useMemo(() => {
    return {
      color: value,
      backgroundColor: value,
    }
  }, [value])

  return (
    <div className="relative h-[14px] w-8 cursor-pointer overflow-hidden rounded-full border-2 border-white shadow-line shadow-current" style={renderedStyle}>
      <input type="color" className="cursor-pointer opacity-0" value={value} onChange={handleChange} />
    </div>
  )
})

export default ColorPicker
