const initialState = {
  theme: 'normal',
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'update':
      return Object.assign({}, state, action.payload)
    default:
      return state
  }
}

export default { namespace: 'appearance', initialState, reducer }
