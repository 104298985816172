import { strapiErrorMessages } from 'constants/errors'

export const formatMessage = (message) => {
  message = `${message}`
  return message.includes('__MSG_BOUNDARY__') ? message.split('__MSG_BOUNDARY__').slice(-1)[0] : message
}

export const transformPageResponse = (response) => {
  const {
    data: items,
    meta: {
      pagination: { total: count },
    },
  } = response

  return { items, count }
}

export const exposeErrorMessage = (error) => {
  let errorMessage

  if (typeof error === 'string') {
    errorMessage = error
  } else {
    errorMessage = error?.error?.message || error.error || error.message || error
  }

  return strapiErrorMessages[errorMessage] || errorMessage
}
