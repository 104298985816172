import React from 'react'
import NavBar from 'components/modules/base/NavBar'
import Footer from 'components/modules/base/Footer'

const DefaultLayout = React.memo(({ className, overrideClassName, withNavBar, navBarFloat, navBarTransparent, navBarContentStyle, withFooter, children }) => {
  const layoutClassName = overrideClassName ? className : `layout default-layout flex flex-col flex-1 items-stretch overflow-auto ${className}`

  return (
    <>
      {withNavBar && <NavBar float={navBarFloat} transparent={navBarTransparent} contentStyle={navBarContentStyle} />}
      <main className={layoutClassName}>
        {withNavBar && <div className="h-14 w-full flex-shrink-0 bg-white" />}
        {children}
        {withFooter && <Footer />}
      </main>
    </>
  )
})

DefaultLayout.defaultProps = {
  className: 'overflow-hidden bg-white',
  overrideClassName: false,
  withNavBar: true,
  withFooter: true,
}

export default DefaultLayout
