import React, { useMemo, useCallback } from 'react'
import { getIndexedArray } from 'utils/base'
import Select from 'components/common/Select'

const monthDaysMap = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
const yearOptions = [{ label: '----', value: '' }].concat(getIndexedArray(new Date().getFullYear() - 1930 + 1, 1930, true).map((value) => ({ value, label: `${value}年` })))
const monthOptions = [{ label: '--', value: '' }].concat(getIndexedArray(12, 1).map((value) => ({ value, label: `${value}月` })))

const getDayOptions = (year, month) => {
  return getIndexedArray(month === 2 ? (year % 4 ? 28 : 29) : monthDaysMap[month - 1], 1).map((value) => ({
    value,
    label: `${value}`,
  }))
}

export const DateSelect = React.memo((props) => {
  const { name, withDays, withMonths, withYears, onChange } = props
  const value = props.value || []
  const [year = '', month = '', day = ''] = value
  const dayOptions = useMemo(() => {
    return [{ label: '--', value: '' }].concat(getDayOptions(year, month))
  }, [year, month])

  const handleChange = useCallback(
    (singleValue, singleName) => {
      const nextValue = value.slice()
      const [, prevMonth, prevDay] = nextValue

      singleValue = singleValue * 1

      if (singleName === 'year') {
        nextValue[0] = singleValue
        if (!singleValue % 4 && prevMonth === 2 && prevDay === 29) {
          nextValue[2] = 28
        }
      } else if (singleName === 'month') {
        nextValue[1] = singleValue
        if (monthDaysMap[singleValue - 1] === 30 && prevDay === 31) {
          nextValue[2] = 30
        }
      } else {
        nextValue[2] = singleValue
      }

      onChange(nextValue, name)
    },
    [name, value, onChange]
  )

  return (
    <div className="component-date-select flex items-center space-x-2">
      {withYears && <Select className="flex-1" options={yearOptions} value={year} name="year" onChange={handleChange} />}
      {withMonths && <Select className="w-32 flex-none" options={monthOptions} value={month} name="month" onChange={handleChange} />}
      {withDays && <Select className="w-32 flex-none" options={dayOptions} value={day} name="day" onChange={handleChange} />}
    </div>
  )
})

DateSelect.defaultProps = {
  withDays: true,
  withMonths: true,
  withYears: true,
  value: [],
}

export const DatePicker = null
