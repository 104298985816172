import React, { useCallback, useEffect, useState } from 'react'
import Icon from '@mdi/react'
import { mdiMenu, mdiClose, mdiBellOutline, mdiChevronRight, mdiMenuDown, mdiHomeLightningBolt, mdiNewspaperVariant, mdiViewGridPlus, mdiAccountCog, mdiPower, mdiCog, mdiAccount } from '@mdi/js'
import Link from 'next/link'
import cx from 'classnames'
import { useBoolean } from 'ahooks'
import { logout } from 'actions/user'
import { useStore } from 'store'
import { confirm } from 'helpers/UI'
import Logo from 'components/modules/base/Logo'
import Image from 'components/common/Image'
import NavLink from 'components/common/NavLink'

export const NotificationEntry = React.memo(() => {
  const [hasUnreadNotifications, setHasUnreadNotifications] = useState(true)

  useEffect(() => {
    // requestHasUnreadNotifications().then((res) => setHasUnreadNotifications(res.result))
  }, [])

  return (
    <div className="relative flex cursor-pointer items-center">
      <Icon className="mr-4 text-gray-400" size={0.8} path={mdiBellOutline} />
      {hasUnreadNotifications && <span className="absolute top-0 left-4 h-1 w-1 rounded-full bg-red-500" />}
    </div>
  )
})

export const ProfileEntry = React.memo((props) => {
  const { profile, dispatch, inDashboard } = props
  const [showDropMenu, setShowDropMenu] = useState(false)

  const handletoggleNavBar = useCallback(() => {
    setShowDropMenu((showDropMenu) => !showDropMenu)
  }, [])

  const handleCloseOutside = useCallback((event) => {
    if (event.target.closest('.profile-entry')) return
    if (!event.target.closest('.profile-drop-menu')) {
      setShowDropMenu(false)
    }
  }, [])

  const handleLogout = useCallback(async () => {
    const confirmed = await confirm({
      title: '确定要退出账号吗？',
      type: 'primary',
      content: '稍后可重新通过账号密码进行登录',
    })

    confirmed && dispatch(logout())
  }, [])

  useEffect(() => {
    document.body.addEventListener('click', handleCloseOutside)
    return () => {
      document.body.removeEventListener('click', handleCloseOutside)
    }
  }, [])

  if (!profile.authorized) {
    return (
      <div className="z-2 my-4 mx-4 flex lg:m-0 lg:items-center">
        <Link href="/auth/register">
          <button className={`mr-1 h-12 flex-1 rounded border border-primary/50 text-sm font-medium text-primary transition-colors hover:bg-primary/10 lg:h-9 lg:w-24`}>注册</button>
        </Link>
        <Link href="/auth/login">
          <button className={`button-primary ml-1 h-12 flex-1 rounded text-sm font-medium transition-colors lg:h-9 lg:w-24`}>登录</button>
        </Link>
      </div>
    )
  }

  const dropDownClassNames = cx('absolute z-10 top-14 right-5 w-64 mt-2 bg-white rounded-md shadow-around-2xl shadow-black/10 transition', {
    'opacity-0 translate-y-4 pointer-events-none': !showDropMenu,
    'opacity-100 translate-y-0': showDropMenu,
  })

  return (
    <div className="flex flex-col items-stretch lg:flex-row lg:items-center">
      {/*{<NotificationEntry />}*/}
      <div
        className={`profile-entry flex cursor-pointer items-center justify-between text-gray-600 ${
          !inDashboard ? 'mt-4 rounded-lg bg-gray-100 p-4 px-2 lg:mt-0 lg:bg-transparent lg:p-0 lg:px-0' : ''
        }`}
        onClick={handletoggleNavBar}>
        <div className="flex items-center">
          <Image src={profile.avatar} type="avatar" alt={profile.nickname} crop="64x64" className="h-8 w-8 rounded border border-gray-300 bg-white transition-colors hover:border-primary" />
          <span className={`${!inDashboard ? '' : 'hidden lg:inline'} user-select-none ml-2 max-w-[100px] overflow-hidden text-ellipsis text-sm font-medium`}>{profile.nickname}</span>
        </div>
        <Icon className="opacity-50" path={mdiMenuDown} size={0.8} />
      </div>
      <div className={dropDownClassNames}>
        <div className="flex items-start rounded-t-md border border-black/10 bg-primary p-4">
          <Image src={profile.avatar} type="avatar" alt={profile.nickname} crop="80x80" className="h-10 w-10 rounded border-2 border-white bg-white" />
          <div className="ml-2 flex flex-1 flex-col items-start">
            <span className="text-base font-medium text-white">{profile.nickname}</span>
            <span className="text-xs text-white/60">{profile.bio || '简介还没想好~'}</span>
          </div>
        </div>
        <div className="flex flex-wrap items-stretch justify-center gap-2 py-4">
          <Link href="/me/dashboard">
            <a className="flex cursor-pointer flex-col items-center justify-between rounded py-2 px-2 transition-colors hover:bg-black/3">
              <Icon path={mdiHomeLightningBolt} size={1.4} className="rounded bg-indigo-500/10 p-[6px] text-indigo-500" />
              <span className="mt-1 text-xs font-medium text-gray-500">总览</span>
            </a>
          </Link>
          <Link href="/me/documents">
            <a className="flex cursor-pointer flex-col items-center justify-between rounded py-2 px-2 transition-colors hover:bg-black/3">
              <Icon path={mdiNewspaperVariant} size={1.4} className="rounded bg-lime-600/10 p-[6px] text-lime-600" />
              <span className="mt-1 text-xs font-medium text-gray-500">简历</span>
            </a>
          </Link>
          <Link href="/me/portfolios">
            <a className="flex cursor-pointer flex-col items-center justify-between rounded py-2 px-2 transition-colors hover:bg-black/3">
              <Icon path={mdiViewGridPlus} size={1.4} className="rounded bg-amber-500/15 p-[6px] text-amber-500" />
              <span className="mt-1 text-xs font-medium text-gray-500">作品</span>
            </a>
          </Link>
          <Link href={`/u/${profile.username}`}>
            <a className="flex cursor-pointer flex-col items-center justify-between rounded py-2 px-2 transition-colors hover:bg-black/3">
              <Icon path={mdiAccount} size={1.4} className="rounded bg-red-500/10 p-[6px] text-red-500" />
              <span className="mt-1 text-xs font-medium text-gray-500">主页</span>
            </a>
          </Link>
        </div>
        <div className="flex items-center justify-between border-t border-gray-200 p-4">
          <Link href="/me/settings/profile">
            <a className="flex items-center text-slate-500">
              <Icon path={mdiCog} size={0.6} />
              <span className="ml-1 text-tiny">账号设置</span>
            </a>
          </Link>
          <button onClick={handleLogout} className="flex items-center text-slate-500">
            <Icon path={mdiPower} size={0.7} />
            <span className="ml-1 text-tiny">退出登录</span>
          </button>
        </div>
      </div>
    </div>
  )
})

const NavBarLink = (props) => {
  const { exact, href, label, subPaths } = props

  return (
    <NavLink
      exact={exact}
      href={href}
      match="asPath"
      subPaths={subPaths}
      className="flex w-full items-center justify-between rounded-md px-4 py-4 text-center text-base font-medium transition-colors lg:w-auto lg:py-2"
      normalClassName="text-gray-600 hover:text-primary"
      activeClassName="text-primary">
      {label}
      <Icon path={mdiChevronRight} className="text-gray-200 lg:hidden" size={1} />
    </NavLink>
  )
}

const NavBar = React.memo((props) => {
  const { contentStyle } = props
  const [navBarVisible, { toggle: toggleNavBar, setFalse: hideNavBar }] = useBoolean(false)
  const { state, dispatch } = useStore()
  const { user } = state

  const className = cx('print-hidden fixed top-0 left-0 z-1000 flex items-center justify-between w-screen h-14 px-6 shadow-thin-line shadow-black/7 bg-white')

  return (
    <div className={className}>
      <Logo contentStyle={contentStyle} withText />
      <div
        className={`${
          navBarVisible ? 'flex' : 'hidden'
        } absolute top-0 right-0 left-0 z-1 h-screen flex-1 flex-col items-stretch bg-white px-2 pt-12 lg:static lg:flex lg:h-auto lg:flex-row lg:items-center lg:justify-end lg:bg-none lg:p-0`}>
        <div onClick={hideNavBar} className="botder-t top-0 left-72 right-72 flex flex-col items-center justify-center border-gray-200 lg:absolute lg:h-14 lg:flex-row lg:space-x-10 lg:border-none">
          <NavBarLink href="/" label="首页" exact />
          <NavBarLink href="/templates" label="简历模板" />
          <NavBarLink href="/faq" label="常见问题" />
          <NavBarLink href="/articles" label="博客文章" />
        </div>
        <ProfileEntry contentStyle={contentStyle} dispatch={dispatch} profile={user} />
      </div>
      <button className="button-normal z-2 flex h-10 w-10 items-center justify-center rounded-lg lg:hidden" onClick={toggleNavBar}>
        <Icon path={navBarVisible ? mdiClose : mdiMenu} size={1} />
      </button>
    </div>
  )
})

NavBar.defaultProps = {
  float: true,
  transparent: false,
}

export default NavBar
