import axios from 'axios'
import qs from 'qs'
import { transformPageResponse, exposeErrorMessage } from 'utils/format'

// DEBUG
process.env['NODE_TLS_REJECT_UNAUTHORIZED'] = '0'

axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.NEXT_PUBLIC_API_BASE_URL || 'https://jianli.online/api'

if (axios.interceptors.request.handlers.length > 0) {
  axios.interceptors.request.handlers = []
}
if (axios.interceptors.response.handlers.length > 0) {
  axios.interceptors.response.handlers = []
}

axios.interceptors.response.use(
  (response) => {
    return response.data
  },
  (error) => {
    try {
      const { data, status } = error.response

      return Promise.reject({ message: exposeErrorMessage(data), code: data?.error?.status || status })
    } catch {
      return Promise.reject({ message: error.message || '请求错误', code: 400 })
    }
  }
)

export const requestPaginationData = async (path, params, requestOptions) => {
  const { page, pageSize, ...restParams } = params || {}

  const queryString = qs.stringify(
    {
      pagination: { page, pageSize },
      ...restParams,
    },
    {
      encodeValuesOnly: true,
    }
  )

  const response = await axios.get(`${path.includes('?') ? `${path}&` : `${path}?`}${queryString}`, requestOptions)

  return transformPageResponse(response)
}

export default axios
