const initialState = {
  pageTitle: null,
  modals: [],
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'update':
      return Object.assign({}, state, action.payload)
    case 'create-modal':
      return Object.assign({}, state, {
        modals: state.modals.concat(action.payload),
      })
    case 'destroy-modal':
      return Object.assign({}, state, {
        modals: state.modals.filter((modal) => modal.id !== action.payload),
      })
    default:
      return state
  }
}

export default { namespace: 'common', initialState, reducer }
