import dynamic from 'next/dynamic'
import Default from './Default'

const Dashboard = dynamic(() => import('./Dashboard').then((mod) => mod.default), { ssr: false })

export default {
  default: (page, options = {}) => (
    <Default className={options.layoutClassName} {...options}>
      {page}
    </Default>
  ),
  dashboard: (page, options = {}) => (
    <Dashboard className={options.layoutClassName} {...options}>
      {page}
    </Dashboard>
  ),
}
