import React, { useCallback, useMemo, useState } from 'react'
import cx from 'classnames'
import ReactSelect from 'react-select'
import './styles.scss'

const themedClassNames = {
  gray: 'bg-gray-100 text-gray-500 ',
  white: 'bg-white text-gray-500',
  none: '',
}

const noOptionsMessage = () => '暂无选项'

const Select = React.memo((props) => {
  const { name, value, onChange, options, disabled, children, size, theme, width, className, ...restProps } = props
  const handleChange = useCallback((option) => onChange && onChange(option?.value, name), [name, onChange])

  const renderedValue = useMemo(() => (typeof value !== 'undefined' ? (options || []).find((item) => item.value === value) || null : null), [options, value])

  const componentTheme = useCallback(
    (theme) => ({
      ...theme,
      colors: {
        ...theme.colors,
        primary25: '#5865f240',
        primary: '#5865f2',
      },
    }),
    []
  )

  const style = useMemo(() => {
    return {
      width,
      flex: 'none',
    }
  }, [width])

  return (
    <ReactSelect
      onChange={handleChange}
      className={cx('component-select flex-1 rounded text-sm', themedClassNames[theme], size, className)}
      classNamePrefix="react-select"
      defaultValue={renderedValue}
      placeholder="请选择"
      noOptionsMessage={noOptionsMessage}
      style={style}
      theme={componentTheme}
      options={options}
      value={renderedValue}
      {...restProps}
    />
  )
})

Select.defaultProps = {
  size: 'medium',
  theme: 'gray',
  isClearable: true,
  isSearchable: true,
}

export default Select
