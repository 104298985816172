import React, { useCallback, useMemo, forwardRef } from 'react'
import cx from 'classnames'

const themedClassNames = {
  gray: 'bg-gray-100 text-gray-500 ',
  white: 'bg-white text-gray-500',
  none: '',
}

const sizeClassNames = {
  tiny: 'h-8 text-xs',
  small: 'h-10 text-tiny',
  normal: 'h-12 text-sm',
  large: 'h-14 text-sm'
}

const Input = React.memo(
  forwardRef((props, ref) => {
    const { type, name, value, onChange, icon, size, theme, pure, width, className, ...restProps } = props

    const handleChange = useCallback(
      (event) => {
        onChange && onChange(event.target.value, name, event)
      },
      [name, onChange]
    )

    const style = useMemo(() => {
      return width ? { width } : {}
    }, [width])

    const appliedClassName = cx({ 'px-4 rounded transition focus:shadow-line focus:shadow-primary': !pure }, sizeClassNames[size], themedClassNames[theme], className)

    return <input type={type} ref={ref} className={appliedClassName} style={style} value={value} onChange={handleChange} {...restProps} />
  })
)

export const Textarea = React.memo(
  forwardRef((props, ref) => {
    const { name, value, onChange, width, height, theme, className, ...restProps } = props

    const handleChange = useCallback(
      (event) => {
        onChange(event.target.value, name, event)
      },
      [name, onChange]
    )

    const renderedStyle = useMemo(() => {
      return { width, height }
    }, [width, height])

    return (
      <textarea
        ref={ref}
        className={cx(`rounded p-4 text-sm transition focus:shadow-line focus:shadow-primary`, className, themedClassNames[theme])}
        style={renderedStyle}
        value={value}
        onChange={handleChange}
        {...restProps}
      />
    )
  })
)

Input.defaultProps = {
  type: 'text',
  label: '',
  value: '',
  className: '',
  theme: 'gray',
  size: 'normal',
}

Textarea.defaultProps = {
  label: '',
  value: '',
  className: '',
  theme: 'gray',
}

export default Input
